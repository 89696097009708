/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content {
  width: 100%;
  height: 100%;
  background-color: white;
}
.altai-theme-content .attached-file,
.altai-theme-content .attached-link {
  padding: 0;
  margin-bottom: 10px;
  text-decoration: none;
}
.altai-theme-content .attached-file-icon,
.altai-theme-content .attached-link-icon {
  margin-right: 10px;
}
.altai-theme-content a {
  color: #000;
}
.altai-theme-content a:hover {
  color: #000;
}
.altai-theme-content .container {
  /*width: 60%;
        margin: auto;*/
}
.altai-theme-content .container .actu-title-text {
  color: black;
  font-size: 20px;
  padding-top: 0;
  margin-top: 0;
}
.altai-theme-content .container .actu-title-date {
  color: #292929;
}
.altai-theme-content .actu-tags {
  padding-top: 40px;
  padding-bottom: 40px;
}
.altai-theme-content .actu-tags .actu-tags-chip {
  background-color: #eee;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  border-radius: 10px;
  margin-bottom: 8px;
  margin-left: 10px;
  display: inline-block;
}
.altai-theme-content .actu-section {
  margin-top: 20px;
}
.altai-theme-content .actu-section .actu-section-title {
  font-size: 20px;
  margin-top: 30px;
}
.altai-theme-content .share-icon {
  float: right;
  font-size: 20px;
}
.altai-theme-content .actu-pdf {
  color: red;
  font-size: 25px;
}
.altai-theme-content .actu-person-icon {
  border-radius: 50% 50% 50% 50%;
  width: 100px;
  height: 100px;
  overflow: hidden;
}
.altai-theme-content .actu-person-icon img {
  width: 100px;
}
.altai-theme-content .actu-nieuwsbrief-link {
  font-size: 22px;
}
